import './recent-stories.scss';
import { TileSlider } from '../tile-slider/tile-slider';

class RecentStories {
    constructor (element, options) {
        const defaults = {
            initAttr: 'data-recent-stories'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$recentStories = element;
    }

    initialize () {
        this.initSlider(this.$recentStories);
    }

    initSlider (slider) {
        this.slider = new TileSlider(slider, {
            initAttr: this.settings.initAttr,
            slidesPerView: 1,
            slidesPerGroup: 1,
            breakpoints: {
                1279: {
                    slidesPerView: 3,
                    slidesPerGroup: 3
                },
                767: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                }
            }
        });
    }
}

export { RecentStories };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$recentStories = $context.querySelectorAll('[data-recent-stories="root"]');

        for (let i = 0; i < $$recentStories.length; i++) {
            const $recentStories = new RecentStories($$recentStories[i]);
            $recentStories.initialize();
        }
    }
});
