import { Slider } from '../slider/slider';

class TileSlider {
    constructor (element, options) {
        const defaults = {
            initAttr: 'data-tileslider',
            breakpoints: {
                1023: {
                    slidesPerView: 3,
                    slidesPerGroup: 1
                },
                767: {
                    slidesPerView: 1,
                    slidesPerGroup: 1
                }
            },
            slidesPerView: 4
        };

        this.settings = Object.assign({}, defaults, options);
        this.$tileSlider = element;

        this.initialize();
    }

    initialize () {
        this.initTileSlider(this.$tileSlider);
    }

    initTileSlider (element) {
        this.tileSlider = new Slider(element, {
            initAttr: this.settings.initAttr,
            autoHeight: true,
            breakpoints: this.settings.breakpoints,
            calculateHeight: false,
            slidesPerView: this.settings.slidesPerView,
            shortSwipes: true,
            parallax: true,
            speed: 750
        });
    }
}

export { TileSlider };
